import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useModal } from "utils/ModalContext";
import Button from "components/button";

import connectIcon from "assets/images/icons/connect.png";
import EvmConnectBtn from "../ConnectionButtons/EvmConnectBtn";
import TonConnectBtn from "../ConnectionButtons/TonConnectBtn";
import SolanaConnectButton from "../ConnectionButtons/SolanaConnectButton";


const WalletConnect = () => {
  const { selectedNetwork, setSelectedNetwork } = useModal();
  const [cookies] = useCookies();

  useEffect(() => {
    const savedNetwork = cookies?.networkSelected;
    if (savedNetwork) setSelectedNetwork(savedNetwork);
  }, [cookies, setSelectedNetwork]);

  return (
    <>
      {selectedNetwork && (selectedNetwork === "ETH" || selectedNetwork === "BSC") && (
        <EvmConnectBtn />
      )}
      {selectedNetwork && selectedNetwork === "TON" && <TonConnectBtn />}
      {selectedNetwork && selectedNetwork === "SOL" && (
        <SolanaConnectButton />
      )}
      {!selectedNetwork && (
        <Button
          href="# "
          sm
          variant="white"
          className="connect_btn"
          disabled={!selectedNetwork}
        >
          <img src={connectIcon} alt="icon" />
          Connect
        </Button>
      )}
    </>
  );
};

export default WalletConnect;
