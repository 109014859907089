import React from "react";
import { Routes, Route } from "react-router-dom";
import HomeThree from "pages/home";
import ProjectsGrid from "pages/projects";
import ProjectDetails from "pages/project-details-1";
import LeaderboardPage from "pages/leaderboard";
import IdoApply from "pages/ido-apply";
import RoadmapDetails from "pages/roadmap";
import Layout from "components/layout";


function Router() {
  return (
    <Routes>
        <Route path="/" element={<Layout/>} >
            <Route path="/" element={<HomeThree />} exact />
            <Route path="/projects" element={<ProjectsGrid />} />
            <Route
                path="/projects-details/:address/:network"
                element={<ProjectDetails />}
            />
            <Route path="/leaderboard" element={<LeaderboardPage />} />
            <Route path="/ido-apply" element={<IdoApply />} />
            <Route path="/roadmap" element={<RoadmapDetails />} />
        </Route>
    </Routes>
  );
}

export default Router;
