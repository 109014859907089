import React from "react";

import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { RKProvider } from "./SdkProviders/RainbowProvider";
import { SolanaProvider } from "./SdkProviders/SolanaProvider";
import Router from "./Router";

import "react-toastify/dist/ReactToastify.css";
import "@rainbow-me/rainbowkit/styles.css";
import "@solana/wallet-adapter-react-ui/styles.css";

function App() {
  return (
    <RKProvider>
      <TonConnectUIProvider 
        manifestUrl="https://tonpad.app/tonconnect-manifest.json"
        walletsListConfiguration={{
          includeWallets: [
            {
              appName: "safepalwallet",
              name: "SafePal",
              imageUrl: "https://s.pvcliping.com/web/public_image/SafePal_x288.png",
              aboutUrl: "https://www.safepal.com/download",
              jsBridgeKey: "safepalwallet",
              platforms: ["ios", "android", "chrome", "firefox"]
            },
            {
              appName: "tonwallet",
              name: "TON Wallet",
              imageUrl: "https://wallet.ton.org/assets/ui/qr-logo.png",
              aboutUrl: "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
              universalLink: "https://wallet.ton.org/ton-connect",
              jsBridgeKey: "tonwallet",
              bridgeUrl: "https://bridge.tonapi.io/bridge",
              platforms: ["chrome", "android"]
            }
          ]
        }}
        actionsConfiguration={{
            twaReturnUrl: 'https://t.me/ToonOfMeme'
        }}
      >
        <SolanaProvider>
          <Router/>
        </SolanaProvider>
      </TonConnectUIProvider>
    </RKProvider>
  );
}

export default App;
