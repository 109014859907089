import IdoApplyStyleWrapper from "./IdoApply.style.js"  
import ApplyForm from "./ApplyForm/ApplyForm"
const IdoApply = () => {

    return (
        <IdoApplyStyleWrapper>
            <div className="container">
                <div className="row">
                    <div className="offset-lg-3 col-lg-6 offset-md-1 col-md-10 col-sm-12">
                        <ApplyForm />
                    </div>
                </div>
            </div> 
        </IdoApplyStyleWrapper>
    )
}

export default IdoApply;