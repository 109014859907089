import { useQueries } from "@tanstack/react-query";
import ProgressBar from "components/progressBar";
import CardHover from "components/cardHover";
import ProjectCardStyleWrapper from "./ProjectCard.style";
import {getPoolBalance} from "api/getPoolBalance";

const ProjectCard = ({
  id,
  thumb,
  title,
  price,
  tgeTime,
  totalValue,
  coinIcon,
  pool_address_ton,
  pool_address_eth,
  pool_address_bsc,
  pool_address_sol,
  network,
}) => {

  const calculateLaunchedDate = () => {
    const launchDate = new Date(tgeTime);
    const currentDate = new Date();
    
    const dayInMillisseconds = 1000 * 60 * 60 * 24;

    const launchDateFromNow = launchDate - currentDate;  
    const launchDateFromNowInDays = Math.ceil(launchDateFromNow / dayInMillisseconds);

    if(launchDateFromNowInDays === 0) return `today`
    if(launchDateFromNowInDays > 0) return `in ${launchDateFromNowInDays} days`
    return `${Math.abs(launchDateFromNowInDays)} days go`
  }

  const params = () => {
    const networkToPool = {
      TON: pool_address_ton,
      ETH: pool_address_eth,
      BSC: pool_address_bsc,
      SOL: pool_address_sol
    }
    return `/${networkToPool[network]}/${network}`
  }

  const poolNetworksAray = [
    { pool_address: pool_address_bsc, network: "BSC" },
    { pool_address: pool_address_eth, network: "ETH" },
    { pool_address: pool_address_sol, network: "SOL" },
    { pool_address: pool_address_ton, network: "TON" },
  ];

  const combinedQueries = useQueries({
    queries: poolNetworksAray.map((pool_address, network) => ({
      queryKey: ["post", pool_address, network],
      queryFn: () => getPoolBalance(pool_address, network),
      retry: false
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  const getTotalRised = () => {
    if(!combinedQueries?.data) return 0

    return combinedQueries.data.reduce((total, currentBalance) => {
      if (!currentBalance?.data?.balance_in_usd) {
        return total;
      }
      return total + parseFloat(currentBalance.data.balance_in_usd);
    }, 0);

  }

  const totalRised = parseFloat(getTotalRised().toFixed(2));
  const progress = ((totalRised / totalValue) * 100).toFixed(1);
  
  return (
    <ProjectCardStyleWrapper>
      <div className="previous-item hover-shape-border hover-shape-inner">
        <div className="previous-gaming">
          <div className="previous-image">
            <img src={thumb} alt="Previous item thumb" width="70" height="70" />
          </div>
          <div className="previous-price">
            <h4 className="mb-10">
              <a href={`/projects-details${params()}`}> {title} </a>
            </h4>
            <div className="dsc">PRICE (FSC) = {price} USDT</div>
          </div>
        </div>
        <div className="previous-chaining">
          <img src={coinIcon} alt="Chain icon" width="35" height="35"/>
          <span>{calculateLaunchedDate()}</span>
        </div>
        <div className="previous-raise">
          <span>
            {totalRised} USDT ({progress}%)
          </span>
          <ProgressBar progress={progress} />
        </div>
        <CardHover />
      </div>
    </ProjectCardStyleWrapper>
  );
};

export default ProjectCard;
