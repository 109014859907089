import checkIcon from "../../../assets/images/kycimg/RMcheckicon.svg";
import minusIcon from "../../../assets/images/kycimg/munies.svg";

const data = [
  {
    title: "Q2 2024",
    isLunched: true,
    checkList: [
      {
        icon: checkIcon,
        text: "Creation of a tier system based on community / Projects.",
      },
      {
        icon: checkIcon,
        text: "Launch of the website.",
      },
      {
        icon: checkIcon,
        text: "Partnership with venture capitals.",
      },
      {
        icon: minusIcon,
        text: "Partnership with KOLs.",
      },
      {
        icon: minusIcon,
        text: "Launch of the first IDOs on the TON network.",
      },
      {
        icon: minusIcon,
        text: "Website with MVP Release",
      },
    ],
  },
  {
    title: "Q3 2024",
    isLunched: true,
    checkList: [
      {
        icon: checkIcon,
        text: "Expansion of local communities",
      },
      {
        icon: checkIcon,
        text: "Improvement of UI/UX",
      },
      {
        icon: checkIcon,
        text: "Hiring crypto project analysts",
      },
      {
        icon: minusIcon,
        text: "Incubation",
      },
    ],
  },
  {
    title: "Q4 2024",
    isLunched: true,
    checkList: [
      {
        icon: checkIcon,
        text: "Development of compatibility for new token standards",
      },
      {
        icon: checkIcon,
        text: "Creation of an investment fund",
      },
      {
        icon: checkIcon,
        text: "TGE TONPAD TOKEN",
      },
    ],
  }
];

export default data;
