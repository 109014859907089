import styled from "styled-components";

const DropdownSelect = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 17px;
  font-family: "Russo One", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  transition: all 0.4s ease-in-out;
  box-sizing: border-box;
  width: 120px;
  height: 40px;
  border: 2px solid #393941;
  background: transparent;
  z-index: 1;
  width: 170px;
  height: 50px;
  cursor: pointer;

  a {
    color: #ffffff;
    text-transform: capitalize;
  }

  span {
    margin: 0;
  }

  svg {
    font-size: 20px !important;
  }

  .wallet_token_list {
    position: absolute;
    left: -2px;
    top: calc(100% + 2px);
    background: #222231;
    width: 240px;
    opacity: 0;
    visibility: hidden;
    transform: scaleY();
    z-index: -999;

    a {
      display: flex;
      align-items: center;
      padding: 20px;
      column-gap: 8px;
      &:hover {
        color: #a3ff12 !important;
      }
    }

    a + a {
      border-top: 1px solid #393941;
    }
  }

  &:hover {
    > span {
      color: #a3ff12;
    }
    .wallet_token_list {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
      z-index: 1;
    }
  }
`;

const ConnectButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`

export {DropdownSelect, ConnectButtonsWrapper};