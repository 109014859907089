import { FiChevronRight } from "react-icons/fi";
import { SectionTitle } from "components/sectionTitle";
import Button from "components/button";
import data from "assets/data/tutorial/dataV2";
import TutorialStyleWrapper from "./Steps.style";

const Tutorial = () => {
  return (
    <TutorialStyleWrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-12 title_group">
            <SectionTitle title="HOW TO PARTICIPATE" subtitle="3 EASY STEPS" />
            {/* <Button href="/kyc-1" sm variant="outline">
              {" "}
              Verify KYC <FiChevronRight />
            </Button> */}
          </div>
          <div className="col-md-12 d-flex right_content">
            {data?.map((step, i) => (
              <div key={i} className="participate-item d-flex col-md-4">
                <div className="number-image">
                  <img src={step.icon} alt="Participate icon" />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">{step.title}</h4>
                  <p className="description">{step.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </TutorialStyleWrapper>
  );
};

export default Tutorial;
