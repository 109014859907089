import { Fragment } from "react";
import PageHeader from "sections/RoadMapDetails/PageHeader";
import RoadMapDetails from "sections/RoadMapDetails";

export default function RoadMapDetailsPage() {
  return (
    <Fragment>
      <PageHeader currentPage="Roadmap details" pageTitle="Roadmap details" />
      <RoadMapDetails />
    </Fragment>
  );
}
