import PartnerStyleWrapper from "./partner.style";

const Partner = ({content}) => {
  return (
    <PartnerStyleWrapper>
      <h4 className="widget_title">Investors</h4>
      <div className="partner_logo_list">
        {content?.map((logo) => (
          <span key={logo} className="partner_logo">
            <img src={logo} alt="partner thumb" />
          </span>
        ))}
      </div>
    </PartnerStyleWrapper>
  );
};

export default Partner;
