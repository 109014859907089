import Button from "components/button";
import {
  useConnectModal,
  useAccountModal,
} from "@rainbow-me/rainbowkit";
import { useMutation } from "@tanstack/react-query";
import { createUser } from "api/createUser";
import connectIcon from "../../../assets/images/icons/connect.png";
import { useModal } from "utils/ModalContext";
import { useWalletClient } from "wagmi";
import { useEffect } from "react";

const EvmConnectBtn = () => {
  const { handleConnectedClient, handleDisconnectedClient, selectedNetwork } =
    useModal();
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();

  const { mutateAsync: createUserFn } = useMutation({
    mutationFn: createUser,
    onError(err) {
      console.log(err);
    },
  });

  const { data } = useWalletClient();

  const compactAddress = (address) => {
    const firstCharacters = address.substring(0, 4);
    const lastCharacters = address.substring(address.length - 4);
    return `${firstCharacters}...${lastCharacters}`;
  };

  useEffect(() => {
    if (data?.account?.address) {
      handleConnectedClient(data?.account?.address);
      createUserFn({
        address: data?.account?.address,
        network: selectedNetwork,
      });
    } else {
      handleDisconnectedClient();
    }
  }, [data?.account?.address, handleConnectedClient, handleDisconnectedClient, createUserFn, selectedNetwork]);

  if (!data?.account?.address) {
    return (
      <Button
        href="# "
        sm
        variant="white"
        className="connect_btn"
        onClick={openConnectModal}
      >
        <img src={connectIcon} alt="icon" />
        Connect
      </Button>
    );
  }

  return (
    <Button
      href="# "
      sm
      variant="white"
      className="connect_btn"
      onClick={openAccountModal}
    >
      <img src={connectIcon} alt="icon" />
      {compactAddress(data?.account?.address)}
    </Button>
  );
};

export default EvmConnectBtn;
