import Button from "components/button";
import {
  useTonConnectModal,
  useTonAddress,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import { useMutation } from '@tanstack/react-query'
import {createUser} from "api/createUser";
import { useModal } from "utils/ModalContext";
import connectIcon from "assets/images/icons/connect.png";
import WalletModal from "components/modal/walletModal/WalletModal";
import { useEffect, useState } from "react";

const TonConnectBtn = () => {
  const { open } = useTonConnectModal();
  const userFriendlyAddress = useTonAddress(true);
  const [tonConnectUI] = useTonConnectUI();
  const [isWalletModalOpened, setIsWalletModalOpened] = useState(false);
  const [copyAddressState, setCopyAddressState] = useState("Copy Address")
  const { handleConnectedClient, handleDisconnectedClient } = useModal();


  const { mutateAsync: createUserFn } = useMutation({
    mutationFn: createUser,
    onError(err) {
      console.log(err)
    },
  })


  const handleConnectButtonClicked = () => {
    if (!userFriendlyAddress) {
      open();
    } else {
      setIsWalletModalOpened(true);
    }
  };

  const handleDisconnect = async () => {
    await tonConnectUI.disconnect();
    setIsWalletModalOpened(false);
    handleDisconnectedClient();
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(userFriendlyAddress);
    setCopyAddressState("Copied")
  };

  const compactAddress = (address) => {
    const firstCharacters = address.substring(0, 4);
    const lastCharacters = address.substring(address.length - 4);
    return `${firstCharacters}...${lastCharacters}`;
  };

  useEffect(() => {
    if(userFriendlyAddress) {
      handleConnectedClient(userFriendlyAddress);
      createUserFn({address: userFriendlyAddress, network: "TON"})
    }
  }, [userFriendlyAddress, handleConnectedClient, createUserFn])

  return (
    <>
      <Button
        sm
        variant="white"
        className="connect_btn"
        onClick={handleConnectButtonClicked}
      >
        <img src={connectIcon} alt="icon" />
        {userFriendlyAddress ? compactAddress(userFriendlyAddress) : "Connect"}
      </Button>
      {isWalletModalOpened && (
        <WalletModal
          cta1={copyAddressState}
          action1={handleCopyAddress}
          cta2="Disconnect"
          action2={handleDisconnect}
          closeModal={() => setIsWalletModalOpened(false)}
        />
      )}
    </>
  );
};

export default TonConnectBtn;
