import { api } from "utils/services";

export async function searchContribution({ wallet_address, network, id_project }) {

  const response = await api.get("/searchContribution", {
    params: {
        wallet_address,
        network,
        id_project
    },
  })
  return response.data;
}