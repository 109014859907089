import RoadMapStyleWrapper from "./RoadMap.style";
import checkIcon from "assets/images/kycimg/RMcheckicon.svg";
import minusIcon from "assets/images/kycimg/munies.svg";

const checkIfItemIsLaunched = (roadmapList) => {
    return (roadmapList.findIndex(item => item.completed) !== -1);
}

const RoadMap = ({content}) => { 
    return (
        <RoadMapStyleWrapper id="roadmap">
            <h4 className="widget_title">Roadmap</h4>
            <div className="row roadmap_items_row">
                {content?.map((roadmapItem) => (
                    <div key={roadmapItem.title} className="col-md-6">
                        <div className="roadmap_item">
                            <h4 className={`roadmap_title ${checkIfItemIsLaunched(roadmapItem.list) && 'roadmap_title_done'}`}>{roadmapItem.title}</h4>
                            <ul>
                                {roadmapItem.list?.map((checklistItem) => (
                                    <li key={checklistItem.name}>
                                        <img src={checklistItem.completed ? checkIcon : minusIcon} alt="icon" />
                                        {checklistItem.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </RoadMapStyleWrapper>
    )
}

export default RoadMap;