import { useState } from "react";
import ModalVideo from "react-modal-video";
import { FaPlay } from "react-icons/fa";
import videoThumb from "assets/images/project/project-video.jpg";
import SummaryStyleWrapper from "./Summary.style";

const Summary = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isVideo = (value) => {
    return value.includes("https://www.youtube.com");
  };

  const getVideoId = (videoLink) => {
    return videoLink?.replace("https://www.youtube.com/watch?v=", "");
  };

  return (
    <SummaryStyleWrapper id="projectSummary">
      <h4 className="widget_title">Project Summary</h4>
      {content.map((summaryItem, index) => (
        <div key={index}>
          {isVideo(summaryItem) ? (
            <div className="vedio_player">
              <img src={videoThumb} alt="video thumb" />
              <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId={getVideoId(summaryItem)}
                onClose={() => setIsOpen(false)}
              />
              <span onClick={() => setIsOpen(true)}>
                {" "}
                <FaPlay />{" "}
              </span>
            </div>
          ) : (
            <p>{summaryItem}</p>
          )}
        </div>
      ))}
    </SummaryStyleWrapper>
  );
};

export default Summary;
