const extraInfo = [
  {
    id: 1,
    tokenName: "Token Test 1",
    tokenSymbol: "TT1",
    decimals: "12",
    address: "0x123456123456dd",
    totalSupply: "3,333,334.00",
    projectSummary: [
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original",
      "https://www.youtube.com/watch?v=1vpzW13m_IM",
    ],
    schedule: [
      {
        round: "Allocation",
        opens: "20.02.2022 08:00:00 UTC",
        closes: "22.02.2022 24:00:00 UTC",
      },
      {
        round: "Validator Round",
        opens: "29.02.2022 05:00:00 UTC",
        closes: "30.02.2022 03:00:00 UTC",
      },
      {
        round: "Staking Round",
        opens: "08.03.2022 12:00:00 UTC",
        closes: "10.03.2022 05:00:00 UTC",
      },
      {
        round: "Sale Ends",
        opens: "15.03.2022 02:00:00 UTC",
        closes: "18.03.2022 16:00:00 UTC",
      },
    ],
    comparison: [
      "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature. Standard chunkde Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original making it over 2000 years old. Richard McClintock",
    ],
    chart: [
      { name: "Private Sale", value: 25 },
      { name: "Staking", value: 14.5 },
      { name: "Liquidity", value: 12 },
      { name: "Ecosystem", value: 10.5 },
      { name: "Advisors", value: 8 },
      { name: "Marketing", value: 13.05 },
      { name: "Team", value: 5.8 },
    ],
    roadmap: [
      {
        title: "Q1 2024",
        list: [
          { name: "Launch on GamFi", completed: true },
          { name: "Seed round", completed: true },
          { name: "Listing on CEX", completed: true },
          { name: "Development of NFT Marketplace", completed: true },
        ],
      },
      {
        title: "Q2 2024",
        list: [
          { name: "First NFT pre-orders", completed: true },
          { name: "Launch of the GAME coin", completed: true },
          { name: "Full-scale platform launch", completed: true },
        ],
      },
      {
        title: "Q1 2025",
        list: [
          { name: "Launch on GamFi", completed: true },
          { name: "Auction system integration", completed: true },
          { name: "Mobile app for iOS and Android", completed: false },
          { name: "Development of NFT Marketplace", completed: false },
          { name: "Game launch", completed: false },
        ],
      },
      {
        title: "Q2 2025",
        list: [
          { name: "Application for crypto exchange", completed: false },
          { name: "Private round", completed: false },
          { name: "New team member added", completed: false },
          { name: "Team expansion", completed: false },
        ],
      },
    ],
    teamMembers: [
      {
        avatar: "/images/team/team-image.png",
        name: "Berneice Tran",
        designation: "Director",
      },
      {
        avatar: "/images/team/team-image8.png",
        name: "Genaro Nadel",
        designation: "VP of Engineering",
      },
      {
        avatar: "/images/team/team-image6.png",
        name: "Lynnette Wilkes",
        designation: "Fullstack Developer",
      },
      {
        avatar: "/images/team/team-image3.png",
        name: "Nathan Bean",
        designation: "Sr. UI Designer",
      },
      {
        avatar: "/images/team/team-image.png",
        name: "Berneice Tran",
        designation: "Director",
      },
      {
        avatar: "/images/team/team-image8.png",
        name: "Genaro Nadel",
        designation: "VP of Engineering",
      },
      {
        avatar: "/images/team/team-image6.png",
        name: "Lynnette Wilkes",
        designation: "Fullstack Developer",
      },
      {
        avatar: "/images/team/team-image3.png",
        name: "Nathan Bean",
        designation: "Sr. UI Designer",
      },
    ],
    investors: [
      "/images/partner/1.png",
      "/images/partner/2.png",
      "/images/partner/3.png",
      "/images/partner/4.png",
      "/images/partner/5.png",
      "/images/partner/6.png",
      "/images/partner/7.png",
      "/images/partner/8.png",
    ],
  },
  {
    id: 2,
    tokenName: "Token Test 2",
    tokenSymbol: "TT2",
    decimals: "13",
    address: "0x23423456789tt",
    totalSupply: "1,111,222.00",
    projectSummary: [
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original",
      "https://www.youtube.com/watch?v=1vpzW13m_IM",
    ],
    schedule: [
      {
        round: "Allocation test",
        opens: "20.02.2022 08:00:00 UTC",
        closes: "22.02.2022 24:00:00 UTC",
      },
      {
        round: "Validator Round",
        opens: "29.02.2022 05:00:00 UTC",
        closes: "30.02.2022 03:00:00 UTC",
      },
      {
        round: "Staking Round",
        opens: "08.03.2022 12:00:00 UTC",
        closes: "10.03.2022 05:00:00 UTC",
      },
      {
        round: "Sale Ends",
        opens: "15.03.2022 02:00:00 UTC",
        closes: "18.03.2022 16:00:00 UTC",
      },
    ],
    comparison: [
      "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature. Standard chunkde Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original making it over 2000 years old. Richard McClintock",
    ],
    chart: [
      { name: "Private Sale", value: 25 },
      { name: "Staking", value: 14.5 },
      { name: "Liquidity", value: 12 },
      { name: "Ecosystem", value: 10.5 },
      { name: "Advisors", value: 8 },
      { name: "Marketing", value: 13.05 },
      { name: "Team", value: 5.8 },
    ],
    roadmap: [
      {
        title: "Q1 2024",
        list: [
          { name: "Launch on GamFi", completed: true },
          { name: "Seed round", completed: true },
          { name: "Listing on CEX", completed: true },
          { name: "Development of NFT Marketplace", completed: true },
        ],
      },
      {
        title: "Q2 2024",
        list: [
          { name: "First NFT pre-orders", completed: true },
          { name: "Launch of the GAME coin", completed: true },
          { name: "Full-scale platform launch", completed: true },
        ],
      },
      {
        title: "Q1 2022",
        list: [
          { name: "Launch on GamFi", completed: true },
          { name: "Auction system integration", completed: true },
          { name: "Mobile app for iOS and Android", completed: false },
          { name: "Development of NFT Marketplace", completed: false },
          { name: "Game launch", completed: false },
        ],
      },
      {
        title: "Q2 2022",
        list: [
          { name: "Application for crypto exchange", completed: false },
          { name: "Private round", completed: false },
          { name: "New team member added", completed: false },
          { name: "Team expansion", completed: false },
        ],
      },
    ],
    teamMembers: [
      {
        avatar: "/images/team/team-image.png",
        name: "Berneice Tran",
        designation: "Director",
      },
      {
        avatar: "/images/team/team-image8.png",
        name: "Genaro Nadel",
        designation: "VP of Engineering",
      },
      {
        avatar: "/images/team/team-image6.png",
        name: "Lynnette Wilkes",
        designation: "Fullstack Developer",
      },
      {
        avatar: "/images/team/team-image3.png",
        name: "Nathan Bean",
        designation: "Sr. UI Designer",
      },
      {
        avatar: "/images/team/team-image.png",
        name: "Berneice Tran",
        designation: "Director",
      },
      {
        avatar: "/images/team/team-image8.png",
        name: "Genaro Nadel",
        designation: "VP of Engineering",
      },
      {
        avatar: "/images/team/team-image6.png",
        name: "Lynnette Wilkes",
        designation: "Fullstack Developer",
      },
      {
        avatar: "/images/team/team-image3.png",
        name: "Nathan Bean",
        designation: "Sr. UI Designer",
      },
    ],
    investors: [
      "/images/partner/1.png",
      "/images/partner/2.png",
      "/images/partner/3.png",
      "/images/partner/4.png",
      "/images/partner/5.png",
      "/images/partner/6.png",
      "/images/partner/7.png",
      "/images/partner/8.png",
    ],
  },
];

export default extraInfo;
