import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import {searchProject} from "api/searchProject"

import Loading from "components/loading/Loading";
import Sidebar from "./SideBar";
import ProjectInfo from "./ProjectInfo/";
import TokenInfo from "./TokenInfo/TokenInfo";
import Summary from "./Summary/Summary";
import Schedule from "./Schedule/Schedule";
import Comparison from "./Comparison/Comparison";
import Statistics from "./Statistics";
import RoadMap from "./RoadMap/RoadMap";
import Team from "./Team";
import Partner from "./Investor";

import ProjectDetailsStyleWrapper from "./ProjectDetails.style";
import extraInfo from "assets/data/projects/extraInfo";



const ProjectDetails = () => {
  const { address, network } = useParams();

  const {
    data: projectDetails,
    isLoading: isLoadingProject,
    error
  } = useQuery({
    queryKey: ["searchProject"],
    queryFn: () => searchProject({
      pool_address: address,
      network
    }),
    retry: 2
  });

  if(error) console.log(error)


  const projectExtraInfo = extraInfo.find(
    (info) => info.id === projectDetails?.data?.id
  );

  const firstBoxTokenData = {
    title: projectDetails?.data?.title,
    tokenInfo: [
      { title: "Max. Allocation", text: projectDetails?.data?.max_allocation },
      { title: "Token Price", text: projectDetails?.data?.price },
      { title: "Access type", text: projectDetails?.data?.access },
    ],
  };

  const secondBoxTokenData = {
    title: "Token Info",
    tokenInfo: [
      { title: "Token Name", text: projectDetails?.data?.title },
      { title: "Token Symbol", text: projectExtraInfo?.tokenSymbol },
      { title: "Decimals", text: projectExtraInfo?.decimals },
      { title: "Address", text: projectExtraInfo?.address },
      { title: "Total Supply", text: projectExtraInfo?.totalSupply },
    ],
  };

  if (isLoadingProject)
    return (
      <ProjectDetailsStyleWrapper>
        <div className="container pb-5">
          <div className="row">
            <Loading rowAmount={2} colAmount={1} Itemheight="21rem" />
          </div>
        </div>
      </ProjectDetailsStyleWrapper>
    );

  if (!projectDetails?.data)
    return (
      <ProjectDetailsStyleWrapper>
        <div className="container pb-5">
          <div className="row">
            <h3>Sorry )=. Try again later</h3>
          </div>
        </div>
      </ProjectDetailsStyleWrapper>
    );

  return (
    <ProjectDetailsStyleWrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ProjectInfo project={projectDetails?.data} />
          </div>
        </div>
        <div className="row token_info_row">
          <div className="col-sm-6">
            <TokenInfo {...firstBoxTokenData} />
          </div>
          <div className="col-sm-6">
            <TokenInfo {...secondBoxTokenData} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Sidebar />
          </div>
          <div className="col-md-8">
            {projectExtraInfo?.projectSummary && (
              <Summary content={projectExtraInfo.projectSummary} />
            )}
            {projectExtraInfo?.schedule && (
              <Schedule content={projectExtraInfo.schedule} />
            )}
            {projectExtraInfo?.comparison && (
              <Comparison content={projectExtraInfo.comparison} />
            )}
            {projectExtraInfo?.chart && (
              <Statistics content={projectExtraInfo.chart} />
            )}
            {projectExtraInfo?.roadmap && (
              <RoadMap content={projectExtraInfo.roadmap} />
            )}
            {projectExtraInfo?.teamMembers && (
              <Team content={projectExtraInfo.teamMembers} />
            )}
            {projectExtraInfo?.investors && (
              <Partner content={projectExtraInfo.investors} />
            )}
          </div>
        </div>
      </div>
    </ProjectDetailsStyleWrapper>
  );
};

export default ProjectDetails;
