import { useState, useEffect } from "react";
import { useMutation } from '@tanstack/react-query'
import {createUser} from "api/createUser";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import WalletModal from "components/modal/walletModal/WalletModal";
import { useModal } from "utils/ModalContext";

import Button from "../../button";
import connectIcon from "../../../assets/images/icons/connect.png";

const SolanaConnectButton = () => {

  const { setVisible } = useWalletModal();
  const {publicKey, connecting, connected, disconnect } = useWallet();
  const [isWalletModalOpened, setIsWalletModalOpened] = useState(false);
  const [copyAddressState, setCopyAddressState] = useState("Copy Address")
  const { handleConnectedClient, handleDisconnectedClient } = useModal();


  const { mutateAsync: createUserFn } = useMutation({
    mutationFn: createUser,
    onError(err) {
      console.log(err)
    },
  })


  const handleConnectButtonClicked = () => {
    if (!publicKey) {
      setVisible(true);
    } else {
      setIsWalletModalOpened(true);
    }
  };

  const handleDisconnect = async () => {
    disconnect();
    setIsWalletModalOpened(false);
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(publicKey.toBase58());
    setCopyAddressState("Copied")
  };

  const compactAddress = (address) => {
    const stringAddres = address.toBase58();
    const firstCharacters = stringAddres.substring(0, 4);
    const lastCharacters = stringAddres.substring(stringAddres.length - 4);
    return `${firstCharacters}...${lastCharacters}`;
  };

  useEffect(() => {
    if(publicKey) {
      handleConnectedClient(publicKey);
      createUserFn({address: publicKey, network: "SOL"})
    } else {
      handleDisconnectedClient()
    }
  }, [publicKey, handleConnectedClient, handleDisconnectedClient, createUserFn])

  return (
    <>
    <Button
      sm
      variant="white"
      className="connect_btn"
      onClick={handleConnectButtonClicked}
    >
      <img src={connectIcon} alt="icon" />
      {connected ? compactAddress(publicKey) : (connecting ? 'Connecting' : 'Connect')}
    </Button>
    {isWalletModalOpened && (
        <WalletModal
          cta1={copyAddressState}
          action1={handleCopyAddress}
          cta2="Disconnect"
          action2={handleDisconnect}
          closeModal={() => setIsWalletModalOpened(false)}
        />
      )}
    </>
  );
};

export default SolanaConnectButton;
