import ComparisonStyleWrapper from "./Comparison.style"

const Comparison = ({content}) => {
    return (
        <ComparisonStyleWrapper id="comparison">
            <h4 className="widget_title">Comparison</h4>
            <div className="comparison"> 
                {
                    content?.map((paragraph, index) => <p key={index}>{paragraph}</p>)
                }
            </div>
        </ComparisonStyleWrapper>
    )
}

export default Comparison;