import numberImg1 from "../../../assets/images/icons/participate-image.png"
import numberImg2 from "../../../assets/images/icons/participate-image2.png"
import numberImg3 from "../../../assets/images/icons/participate-image3.png"

const data = [
    {
        icon: numberImg1,
        title: "Tiers",
        text: "If you are a top holder of a project enabled in the tier list, select the network where the project is listed to have your wallet enabled in special rounds."
    },
    {
        icon: numberImg2,
        title: "Explore",
        text: "Explore our IDO page and check in which round your wallet is enabled to participate."
    },
    {
        icon: numberImg3,
        title: "Follow",
        text: "After contributing to your desired pre-sale, don't forget to join the respective project's groups to stay informed about all the next steps of the project in question."
    },
]

export default data;