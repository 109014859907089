import {ConnectButtonsWrapper} from "./Connect.style";
import NetworkSelect from "./NetworkSelect/NetworkSelect";
import WalletConnect from "./WalletConnect/WalletConnect";

const Connect = () => {

  return (
    <ConnectButtonsWrapper>
      <NetworkSelect/>
      <WalletConnect/>
    </ConnectButtonsWrapper>
  );
};

export default Connect;
