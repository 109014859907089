const data = [
  {
    id: "1W6WV",
    title: "Home",
    url: "/",
  },
  {
    id: "2ZYYU",
    title: "Projects",
    url: "/projects",
  },
  /* {
    id: "4XP60",
    title: "Leaderboard",
    url: "/leaderboard",
  }, */
  {
    id: "PMQ60",
    title: "Roadmap",
    url: "/roadmap",
  },
  {
    id: "ZZUVV",
    title: "Utils",
    url: "#",
    subMenus: [
      {
        id: "4XL90",
        title: "IDO Apply",
        url: "/ido-apply",
      },
      /* {
        id: "PMQ60",
        title: "Roadmap",
        url: "/roadmap",
      } */
    ],
  },
];

export default data;
