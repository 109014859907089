import { Fragment } from "react";
import PageHeader from "sections/ProjectPages/PageHeader";
import ProjectsGrid from "sections/ProjectPages/ProjectsGrid";

export default function ProjectGridPage() {
  return (
    <Fragment>
      <PageHeader currentPage="PROJECTS" pageTitle="EXPLORE IDOS" />
      <ProjectsGrid />
    </Fragment>
  );
}
