import { Fragment } from "react";
import Banner from "sections/HomeSections/Banner";
import Token from "sections/HomeSections/Token";
import ExploreProjects from "sections/HomeSections/Projects";
import Steps from "sections/HomeSections/Steps";
import Features from "sections/HomeSections/Tokenomics";
import Partner from "sections/HomeSections/Partner";
import Faq from "sections/HomeSections/Faq";

export default function HomeThree() {
  return (
    <Fragment>
      <Banner />
      <Token />
      <ExploreProjects />
      <Steps />
      {/* <Features /> */}
      <Faq />
      <Partner />
    </Fragment>
  );
}
