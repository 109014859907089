import styled from "styled-components";

export const FeaturesStyleWrapper = styled.section`
  background: #151525;
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 2;
  padding-top: 115px;
  padding-bottom: 105px;
  
  .key_points_conttent {
    margin-top: 50px;
    z-index: 1;
  }
  .key_points_item {
    &_img {
      img {
        min-height: 50px;
      }
      margin-bottom: 26px;
    }

    &_text {
      h4 {
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 21px;
        color: #ffffff;
        text-transform: capitalize;
      }
      p {
        max-width: 91%;
        margin-bottom: 26px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .items-row {
      row-gap: 15px;
    }
  }
  @media only screen and (max-width: 767px) {
    padding-top: 4rem;
    padding-bottom: 2rem;
    .key_points_item {
      &_text {
        h4 {
          font-size: 20px;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 430px) {
    .key_points_conttent {
      img {
      height: 3rem;
      object-fit: cover;
      }
    }
  }
`;

export const TokenomicsHeader = styled.div`
  display: grid;
  grid-template-columns: 8% 9% 9% 12% 17% 16% 9%;

  @media(max-width: 768px){
    grid-template-columns: repeat(3, auto);
  }

  .tokenomics-header__item {
    display: block;

    h4 {
      font-size: 1.15rem;
      color: #fff;
    }

    p {
      color: #A0A0A6;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      line-height: 1 !important;
    }

    @media(max-width: 450px){
      h4 {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.75rem;
      margin-bottom: 0.35rem;
    }
    }
  }
`

export const TokenomicsFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 3rem;
 
  .tokenomics-footer__item {
    display: block;

    h4 {
      font-size: 2.5rem;
      color: #6E4AFE;
      margin-bottom: 0.5rem;
      line-height: 1 !important;
    }

    p {
      color: #fff;
      font-size: 1.15rem;
      font-weight: bold;
    }
  }
  @media(max-width: 992px) {

.tokenomics-footer__item {
  h4 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }
}

}

@media(max-width: 768px) {

grid-template-columns: repeat(2, 1fr);
margin-top: 2rem;

.tokenomics-footer__item {
  h4 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }
}

}

@media(max-width: 430px) {

margin-top: 1rem;

.tokenomics-footer__item {
  h4 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.75rem;
  }
}

}
`
