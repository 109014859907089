import {api} from 'utils/services';

export async function searchProject({pool_address, network}) {
    const response = await api.get('/searchProject', {
        params: {
            pool_address,
            network
        },
      })
    return response.data;
}