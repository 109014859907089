import { Fragment } from "react";
import PageHeader from "sections/LeaderboardPage/PageHeader";
import Leaderboard from "sections/LeaderboardPage";

export default function LeaderboardPage() {
  return (
    <Fragment>
      <PageHeader currentPage="ledearboard" pageTitle="ledearboard" />
      <Leaderboard />
    </Fragment>
  );
}
