import ProgressBarStyle from "./ProgressBar.style";

const ProgressBar = ({ progress }) => {
  const progressAdjusted = (!progress ? '50%' : progress > 100 ? '100%' : `${parseInt(progress)}%`)
  return (
    <ProgressBarStyle className="progressbar_wrapper">
      <div className="progress_bar">
        <span className="progress_bar_overlay" style={{ width: progressAdjusted }}></span>
      </div>
    </ProgressBarStyle>
  );
};

export default ProgressBar;
