import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
//import nextArrowIcon from "assets/images/icons/next-arrow.png";
import ProjectCard from "../ProjectCard/ProjectCard";
import {
  ProjectItemsStyleWrapper,
  ProjectGridEmptyMsg,
} from "./ProjectItems.style";

//import coinIcon1 from "assets/images/project/previous-image.png";
//import coinIcon2 from "assets/images/project/previous-image2.png";
///import coinIcon3 from "assets/images/project/previous-image3.png";
//import coinIcon4 from "assets/images/project/chain.png";

const ProjectItems = ({ projects }) => {
  const splitProjects = () => {
    if (!projects) return { upcoming: [], ongoing: [] };

    const upcoming = projects.filter(
      (project) => project.status === "upcoming"
    );
    const ongoing = projects.filter((project) => project.status === "active");
    const previous = projects.filter(
      (project) => project.status === "previous"
    );
    return { upcoming, ongoing, previous };
  };
  const splittedProjects = splitProjects();

  return (
    <ProjectItemsStyleWrapper>
      <div className="container">
        <div className="single-project-row">
          <Tabs>
            <TabList>
              <div className="tab_btn_wrapper">
                <Tab key="Ongoing">
                  <button>Ongoing</button>
                </Tab>
                <Tab key="Upcoming">
                  <button>Upcoming</button>
                </Tab>
                <Tab key="Previous">
                  <button>Previous</button>
                </Tab>
              </div>

              {/* <div className="item_sorting_list">
                <button>
                  All Access
                  <img src={nextArrowIcon} alt="icon" />
                  <ul className="sub-menu">
                    <li>All Access</li>
                    <li>Public</li>
                    <li>Private</li>
                    <li>Community</li>
                  </ul>
                </button>
                <button>
                  All Block Chain
                  <img src={nextArrowIcon} alt="icon" />
                  <ul className="sub-menu">
                    <li>
                      <img src={coinIcon1} alt="icon" /> Binance (BSC)
                    </li>
                    <li>
                      <img src={coinIcon2} alt="icon" /> Ethereum (ETH)
                    </li>
                    <li>
                      <img src={coinIcon3} alt="icon" /> Polygon
                    </li>
                    <li>
                      <img src={coinIcon4} alt="icon" /> All Block Chain
                    </li>
                  </ul>
                </button>
              </div> */}
            </TabList>
            <TabPanel className="row tabs-row">
              {splittedProjects?.ongoing?.length ? (
                splittedProjects.ongoing.map((project) => (
                  <div key={project.id} className="col-lg-4 col-md-6">
                    <ProjectCard {...project} />
                  </div>
                ))
              ) : (
                <ProjectGridEmptyMsg>No ongoing projects</ProjectGridEmptyMsg>
              )}
            </TabPanel>
            <TabPanel className="row tabs-row">
              {splittedProjects?.upcoming?.length ? (
                splittedProjects.upcoming.map((project) => (
                  <div key={project.id} className="col-lg-4 col-md-6">
                    <ProjectCard {...project} />
                  </div>
                ))
              ) : (
                <ProjectGridEmptyMsg>No upcoming projects</ProjectGridEmptyMsg>
              )}
            </TabPanel>
            <TabPanel className="row tabs-row">
              {splittedProjects?.previous?.length ? (
                splittedProjects.previous.map((project) => (
                  <div key={project.id} className="col-lg-4 col-md-6">
                    <ProjectCard {...project} />
                  </div>
                ))
              ) : (
                <ProjectGridEmptyMsg>No previous projects</ProjectGridEmptyMsg>
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </ProjectItemsStyleWrapper>
  );
};

export default ProjectItems;
