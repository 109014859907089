import { Fragment } from "react";
import PageHeader from "sections/IdoApplyPage/PageHeader";
import IdoApply from "sections/IdoApplyPage";

export default function IdoApplypage() {
  return (
    <Fragment>
      <PageHeader pageTitle="APPLY FOR IDO" />
      <IdoApply />
    </Fragment>
  );
}
