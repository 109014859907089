import { FiX } from "react-icons/fi";
import WalletModalStyleWrapper from "./WalletModal.style";
import Button from "components/button";

const WalletModal = ({ cta1, action1, cta2, action2, closeModal }) => {
  return (
    <>
      <WalletModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <button onClick={closeModal}>
                <FiX />
              </button>
            </div>
            <div className="modal_cta">
            <Button
                sm
                variant="outline"
                className="modal_btn"
                onClick={action1}
              >
                {cta1}
              </Button>
              <Button
                sm
                variant="outline"
                className="modal_btn"
                onClick={action2}
              >
                {cta2}
              </Button>
            </div>
          </div>
        </div>
      </WalletModalStyleWrapper>
    </>
  );
};

export default WalletModal;
