import Countdown, { zeroPad } from "react-countdown";
import { useQueries } from "@tanstack/react-query";
import ProgressBar from "components/progressBar";
import ProjectInfoStyleWrapper from "./ProjectInfo.style";
import ProjectInfoActions from "./ProjectInfoActions/ProjectInfoActions";
import {getPoolBalance} from "api/getPoolBalance";


const ProjectInfo = ({ project }) => {
  const {
    projectIcon,
    price,
    totalValue,
    tgeTime,
    max_allocation,
    socialData,
    coinIcon,
    title,
    pool_address_bsc,
    pool_address_eth,
    pool_address_sol,
    pool_address_ton,
  } = project;


  const poolNetworksAray = [
    { pool_address: pool_address_bsc, network: "BSC" },
    { pool_address: pool_address_eth, network: "ETH" },
    { pool_address: pool_address_sol, network: "SOL" },
    { pool_address: pool_address_ton, network: "TON" },
  ];

  const combinedQueries = useQueries({
    queries: poolNetworksAray.map((pool_address, network) => ({
      queryKey: ["post", pool_address, network],
      queryFn: () => getPoolBalance(pool_address, network),
      retry: false
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  const getTotalRised = () => {
    if(!combinedQueries?.data) return 0

    return combinedQueries.data.reduce((total, currentBalance) => {
      if (!currentBalance?.data?.balance_in_usd) {
        return total;
      }
      return total + parseFloat(currentBalance.data.balance_in_usd);
    }, 0);

  }

  const totalRised = parseFloat(getTotalRised().toFixed(2));
  const progress = ((totalRised / totalValue) * 100).toFixed(1);



  const progressBarValue = !progress
    ? "50%"
    : progress > 100
    ? "100%"
    : `${parseInt(progress)}%`;

  const CountdownRender = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="countdown_wrapper">
        <div>
          {zeroPad(days)}
          <span>D</span>
        </div>
        <div>
          {zeroPad(hours)}
          <span>H</span>
        </div>
        <div>
          {zeroPad(minutes)}
          <span>M</span>
        </div>
        <div>
          {zeroPad(seconds)}
          <span>S</span>
        </div>
      </div>
    );
  };

  const isSaleEnded = new Date() > new Date(tgeTime);

  return (
    <ProjectInfoStyleWrapper className="live_project_wrapper">
      <div className="game-price-item">
        <div className="game-price-inner">
          <div className="total-price">
            <div className="price-inner d-flex">
              <div className="image-icon">
                <img src={projectIcon} alt="icon" width="100" height="100" />
              </div>
              <div className="price-details">
                <h3>{title}</h3>
                <div className="dsc">{`PRICE (DDO) = ${price} USDT`}</div>
              </div>
            </div>
            <div className="all-raise">{`Total Raise ${totalRised} USDT ( ${progress}% )`}</div>
          </div>
          <div className="allocation-max text-center">
            <img src={coinIcon} alt="icon" width="50" height="50" />
            <div className="allocation">{`Allocation: ${max_allocation} USDT Max`}</div>
          </div>
          <div className="targeted-raise">
            {isSaleEnded ? (
              <div className="seles-end-bigText">Sale Ended</div>
            ) : (
              <>
                <div className="seles-end-text">Sale End In</div>
                <Countdown
                  date={new Date(tgeTime)}
                  renderer={CountdownRender}
                />
              </>
            )}
            <div className="targeted-raise-amount">
              {`Targeted Raise ${totalValue} USDT`}
            </div>
          </div>
        </div>
        <div className="progress-inner">
          <ProgressBar progress={progressBarValue} />
        </div>

        <div className="project_card_footer">
          {/* <div className="project_card_footer_1row">
            <div className="participants">
              Participants (4017/5000 temos esse dado?)
            </div>
          </div> */}

          {!isSaleEnded && <ProjectInfoActions {...project} />}

          <div className="social_links">
            {socialData?.map((profile, i) => (
              <a key={i} href={profile}>
                {/* <img src={profile.icon} alt="icon" /> */}
              </a>
            ))}
          </div>
        </div>
      </div>
    </ProjectInfoStyleWrapper>
  );
};

export default ProjectInfo;
