import logo1 from "../../../assets/images/partner/1.png";
import logo2 from "../../../assets/images/partner/2.png";
import logo3 from "../../../assets/images/partner/3.png";
import logo4 from "../../../assets/images/partner/4.png";
import logo5 from "../../../assets/images/partner/5.png";
import logo6 from "../../../assets/images/partner/6.png";
import logo7 from "../../../assets/images/partner/7.png";
import logo8 from "../../../assets/images/partner/8.png";
import logo9 from "../../../assets/images/partner/9.png";
import logo10 from "../../../assets/images/partner/10.png";
import logo11 from "../../../assets/images/partner/11.png";
import logo12 from "../../../assets/images/partner/12.png";
import logo13 from "../../../assets/images/partner/13.png";
import logo14 from "../../../assets/images/partner/14.png";
import logo15 from "../../../assets/images/partner/15.png";

const data = [
  {
    partnerType: "Infra",
    logos: [
      logo4,
      logo2,
      logo3,
    ],
  },
  {
    partnerType: "Tiers",
    logos: [
      logo1,
    ],
  },
];

export default data;
