import styled from "styled-components";

const RankingStyleWrapper = styled.div`
  padding: 50px 0 120px;
  background: #090a1a;

  
  @media only screen and (max-width: 767px) {
    
  }
  @media only screen and (max-width: 430px) {
    padding-top: 0;
  }
`;

export default RankingStyleWrapper;
