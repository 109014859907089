import { getProjects } from "api/getProjects";
import { useQuery } from "@tanstack/react-query";
import ProjectItems from "./ProjectItems/ProjectItems";
import ProjectsGridStyleWrapper from "./ProjectsGrid.style";
import Loading from "components/loading/Loading";

const ProjectsGrid = () => {

  const {
    data: projectsResult,
    isLoading: isLoadingProjects,
    error
  } = useQuery({ 
    queryKey: ["getAllProjects"],
    queryFn: () => getProjects(),
    retry: 2
  });

  if(error) console.log(error)

  if (isLoadingProjects)
    return (
      <ProjectsGridStyleWrapper className="pt-5">
          <div className="container pt-5 pb-5">
            <Loading rowAmount={2} colAmount={3} Itemheight="35rem" />
          </div>
      </ProjectsGridStyleWrapper>
    );

  return (
    <ProjectsGridStyleWrapper>
      <ProjectItems projects={projectsResult?.data} />
    </ProjectsGridStyleWrapper>
  );
};

export default ProjectsGrid;
