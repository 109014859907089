import Button from "components/button";
import BannerStyleWrapper from "./Banner.style";

const Banner = () => {
  return (
    <>
      <BannerStyleWrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="gamfi_v2_hero_left">
                <h2>LEADING THE BEST<br /> NARRATIVES AND IDO IN WEB3</h2>
                <p>
                Connecting multichain communities to high-quality assets on The Open Network (TON)
                </p>
                <div className="banner-btns">
                  <Button href="/projects" md variant="mint">
                    Explore IDO on TON
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BannerStyleWrapper>
    </>
  );
};

export default Banner;
