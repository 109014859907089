import { StyleLoader, StyleLoaderWrapper } from "./Loading.style";

const Loading = () => {
  return (
    <StyleLoaderWrapper>
      <StyleLoader/>
    </StyleLoaderWrapper>
  );
};

export default Loading;