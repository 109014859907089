const data = [
  {
    title: "What is IDO?",
    text: `IDO stands for "Initial DEX Offering," which translates to "Initial Offering on a Decentralized Exchange." It's a fundraising method used in blockchain and DeFi (Decentralized Finance) ecosystems. In summary, they are acronyms for rounds preceding the DEX or CEX. There are some variations. IGO = INITIAL GAME OFFER 
   / ICO = INITAL CEX OFFER `,
  },
  {
    title: "How to participate in an IDO?",
    text: `IDOs on TONPAD will provide access to the best communities on the $TON network and other networks, making existing tokens in the market of projects spread across web3 more useful. In this way, we will unite tribes and segment targeted audiences.

    "If you are a top holder of a selected community, you will have special access to early rounds; otherwise, you can participate in public FCFS rounds with slightly higher prices`,
  },
  {
    title: "What is TGE?",
    text: `TGE" stands for "Token Generation Event," which is the initial offering of a cryptocurrency's tokens to investors. It typically occurs during a token sale, such as an ICO or IDO, and marks the beginning of token distribution in the market.`,
  },
  {
    title: "What is Vesting?",
    text: "Vesting is the process by which ownership rights of tokens or stocks are gradually acquired over time, incentivizing long-term retention and commitment to the project or company.",
  },
];

export default data;
