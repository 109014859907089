import { Fragment } from "react";
import { useModal } from "utils/ModalContext";
import ShareModal from "components/modal/shareModal/ShareModal";
import PageHeader from "sections/ProjectPages/ProjectDetails/PageHeader";
import ProjectDetails from "sections/ProjectPages/ProjectDetails";

export default function ProjectsDetailsOne() {
  const { shareModalVisibility } = useModal();
  return (
    <Fragment>
      {shareModalVisibility && <ShareModal />}
      <PageHeader currentPage="PROJECT DETAILS " />
      <ProjectDetails />
    </Fragment>
  );
}
