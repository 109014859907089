import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { searchContribution } from "api/searchContribution";
import { useModal } from "utils/ModalContext";
import Button from "components/button";
import useTransaction from 'hooks/useTransaction';


import { toast } from "react-toastify";

const Contribute = ({
  max_allocation,
  id,
  pool_address_bsc,
  pool_address_eth,
  pool_address_sol,
  pool_address_ton,
}) => {
  const { selectedNetwork, account } = useModal();
  const [amountAllocation, setAmountAllocation] = useState(0);
  const { loading, error, hash, handleContribute } = useTransaction(amountAllocation, pool_address_bsc, pool_address_eth, pool_address_sol, pool_address_ton)


  const {
    data: contributionData,
    isLoading: isSearchingContribution,
    error: searchContributionError,s
  } = useQuery({
    queryKey: ["searchContribution"],
    queryFn: () =>
      searchContribution({
        wallet_address: account,
        network: selectedNetwork,
        id_project: id,
      }),
    retry: 2,
  });

  if (searchContributionError) console.error(searchContributionError);

  const valueLeftToContribute = useMemo(() => {
    const contributions = contributionData?.data;
    const totalContributed = contributions?.reduce(
      (accumulator, currentContribution) =>
        accumulator + currentContribution.contribute_amount,
      0
    );
    const valueLeft = max_allocation - totalContributed;
    return valueLeft;
  }, [contributionData?.data, max_allocation]);

  const handleChangeAllocationValue = (event) => {
    const value = event.target.value;
    setAmountAllocation(value);
    if (value > valueLeftToContribute) {
      toast.info(
        `The maximum amount you can contribute is ${valueLeftToContribute}`
      );
      setAmountAllocation(valueLeftToContribute);
    }
  };

  return (
    <div className="project_card_footer_3row">
      <label htmlFor="TonAddress">Allocation value:</label>
      <input
        type="number"
        min={0}
        max={valueLeftToContribute}
        step="10"
        id="TonAddress"
        className="form-control"
        value={amountAllocation}
        onChange={handleChangeAllocationValue}
      />
      <Button
        sm
        href="#"
        onClick={handleContribute}
        disabled={valueLeftToContribute <= 0 || isSearchingContribution || amountAllocation <= 0}
      >
        {loading ? "loading ..." : "Contribute"}
      </Button>
    </div>
  );
};

export default Contribute;
