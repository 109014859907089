import ScheduleStyleWrapper from "./Schedule.style";

const Schedule = ({ content }) => {
  return (
    <ScheduleStyleWrapper id="schedule">
      <h4 className="widget_title">Schedule</h4>

      <div className="schedule_table">
        <table>
          <tbody>
            <tr className="table-header">
              <th>Round</th>
              <th>Opens</th>
              <th>Closes</th>
            </tr>
            {content?.map((schedulingItem) => (
              <tr key={schedulingItem.round}>
                <td>{schedulingItem.round}</td>
                <td>{schedulingItem.opens}</td>
                <td>{schedulingItem.closes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ScheduleStyleWrapper>
  );
};

export default Schedule;
