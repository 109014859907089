import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import Button from "components/button";
import ApplyFormStyleWrapper from "./ApplyFrom.style";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { number, string, z } from "zod";

const ApplyForm = () => {
  const idoApplyFormData = z.object({
    projectName: z.string().min(1),
    email: z.string().email(),
    brief: z.string(),
    platform: z.enum(["TON", "BSC", "SOL", "ETH"]),
    status: z.enum(["initial", "whitePaper", "development", "production"]),
    raisedFunds: z.enum(["yes", "no"]),
    team: z.enum(["anon", "public", "mixed"]),
    targetRaise: number(),
    website: z.string().url(),
    telegram: z.string(),
    twitter: z.string(),
  });

  const {
    handleSubmit,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm ({
    resolver: zodResolver(idoApplyFormData),
  });

  const submitForm = (formData) => {
    console.log("formData", formData);
  };

  return (
    <ApplyFormStyleWrapper>
      
      <i>Unfortunately we are not yet receiving forms here, contact us on <a href="https://t.me/tonpad_ton/">Telegram.</a></i>
      <br />
      <form
        onSubmit={handleSubmit((formData) => submitForm(formData))}
        noValidate
      >
        <div className="form_widgets">
          <div className="form-group">
            <label htmlFor="projectName">Project Name</label>
            <input
              key="projectName"
              id="projectName"
              name="projectName"
              register={register}
              type="text"
              placeholder="Project Name"
              className="form-control"
            />
            {errors.projectName?.message && <p>errors.projectName.message</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              key="email"
              id="email"
              name="email"
              register={register}
              placeholder="Email"
              className="form-control"
            />
            {errors.email?.message && <p>errors.email.message</p>}
          </div>
          <div className="input_field_wrapper form-group col-md-12">
            <label htmlFor="projectDetails">Project Brief</label>
            <textarea
              id="projectDetails"
              cols="30"
              rows="10"
              placeholder="Project Brief"
              key="brief"
              name="brief"
              register={register}
            ></textarea>
            {errors.brief?.message && <p>errors.brief.message</p>}
          </div>
        </div>

        <div className="form_widgets form_check">
          <h5>BLOCKCHAIN/PLATFORM</h5>
          <label>
            TON
            <input
              type="radio"
              name="platfrom"
              key="platfrom"
              id="platfrom"
              value="TON"
              register={register}
            />
          </label>
          <label>
            BSC
            <input
              type="radio"
              name="platfrom"
              key="platfrom"
              id="platfrom"
              value="BSC"
              register={register}
            />
          </label>
          <label>
            SOL
            <input
              type="radio"
              name="platfrom"
              key="platfrom"
              id="platfrom"
              value="SOL"
              register={register}
            />
          </label>
          <label>
            ETH
            <input
              type="radio"
              name="platfrom"
              key="platfrom"
              id="platfrom"
              value="ETH"
              register={register}
            />
          </label>
          {errors.platfrom?.message && <p>errors.platfrom.message</p>}
        </div>

        <div className="form_widgets form_check">
          <h5>PROJECT STATUS</h5>

          <label>
            Just an initial idea
            <input
              type="radio"
              name="status"
              key="status"
              id="status"
              value="initial"
              register={register}
            />
          </label>
          <label>
            Idea with White Paper
            <input
              type="radio"
              name="status"
              key="status"
              id="status"
              value="whitePaper"
              register={register}
            />
          </label>
          <label>
            In early development
            <input
              type="radio"
              name="status"
              key="status"
              id="status"
              value="development"
              register={register}
            />
          </label>
          <label>
            Ready to launch
            <input
              type="radio"
              name="status"
              key="status"
              id="status"
              value="production"
              register={register}
            />
          </label>
          {errors.status?.message && <p>errors.status.message</p>}
        </div>

        <div className="form_widgets form_check">
          <h5>HAVE YOU ALREADY RAISED FUNDS ?</h5>

          <label>
            Yes
            <input
              type="radio"
              name="raisedFunds"
              key="raisedFunds"
              id="raisedFunds"
              value="yes"
              register={register}
            />
          </label>
          <label>
            No
            <input
              type="radio"
              name="raisedFunds"
              key="raisedFunds"
              id="raisedFunds"
              value="no"
              register={register}
            />
          </label>
          {errors.raisedFunds?.message && <p>errors.raisedFunds.message</p>}
        </div>

        <div className="form_widgets form_check">
          <h5>IS YOUR TEAM ANON OR PUBLIC</h5>
          <label>
            Anon
            <input
              type="radio"
              name="team"
              key="team"
              id="team"
              value="anon"
              register={register}
            />
          </label>
          <label>
            Fully Public
            <input
              type="radio"
              name="team"
              key="team"
              id="team"
              value="public"
              register={register}
            />
          </label>
          <label>
            Mixed
            <input
              type="radio"
              name="team"
              key="team"
              id="team"
              value="mixed"
              register={register}
            />
          </label>
          {errors.team?.message && <p>errors.team.message</p>}
        </div>

        <div className="form_widgets">
          <div className="form-group">
            <label htmlFor="fundterget">TARGET RAISE</label>
            <input
              type="text"
              id="targetRaise"
              name="targetRaise"
              key="targetRaise"
              placeholder="How much are you planning on raising on the IDO?"
              className="form-control"
              register={register}
            />
            {errors.targetRaise?.message && <p>errors.targetRaise.message</p>}
          </div>
          <div className="form-group">
            <label htmlFor="projectLink">
              PROJECT WEBSITE / LINK TO WHITEPAPER
            </label>
            <input
              type="text"
              id="website"
              name="website"
              key="website"
              placeholder="Enter link"
              className="form-control"
              register={register}
            />
            {errors.website?.message && <p>errors.website.message</p>}
          </div>
        </div>

        <div className="form_widgets">
          <div className="form-group">
            <label htmlFor="telegram">TELEGRAM GROUP</label>
            <div className="input_with_icon">
              <div className="input_social_icon">
                <FaTelegramPlane />
              </div>
              <input
                type="text"
                id="telegram"
                name="telegram"
                key="telegram"
                placeholder="Enter telegram group link"
                className="form-control"
                register={register}
              />
              {errors.telegram?.message && <p>errors.telegram.message</p>}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="telegram">Project Twitter</label>
            <div className="input_with_icon">
              <div className="input_social_icon">
                <FaTwitter />
              </div>
              <input
                type="text"
                id="twitter"
                name="twitter"
                key="twitter"
                placeholder="Enter twitter link"
                className="form-control"
                register={register}
              />
              {errors.twitter?.message && <p>errors.twitter.message</p>}
            </div>
          </div>
        </div>

        <Button variant="blue" lg>
          Submit IDO Project
        </Button>
        <br />
        <i>Unfortunately we are not yet receiving forms here, contact us on <a href="https://t.me/tonpad_ton/">Telegram.</a></i>
      </form>
    </ApplyFormStyleWrapper>
  );
};

export default ApplyForm;
