import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { updateUser } from "api/updateUser";
import { searchUser } from "api/searchUser";
import { useModal } from "utils/ModalContext";
import Button from "components/button";
import { FaPencilAlt } from "react-icons/fa";
import Connect from "components/connect/Connect";
import { toast } from "react-toastify";
import Contribute from "./Contribute/Contribute";

const ProjectInfoActions = ({
  totalRised,
  totalValue,
  max_allocation,
  id,
  pool_address_bsc,
  pool_address_eth,
  pool_address_sol,
  pool_address_ton,
}) => {
  const { selectedNetwork, isClientConnected, account } = useModal();
  const [tonAddress, setTonAdress] = useState(null);
  const [tempTonAddress, setTempTonAdress] = useState("");

  const {
    data: userData,
    error: searchUserError,
  } = useQuery({
    queryKey: ["searchUser"],
    queryFn: () =>
      searchUser({
        address: account,
        network: selectedNetwork,
      }),
    retry: 2,
  });

  //if (searchUserError) console.error(searchUserError);

  const { mutateAsync: updateUserFn } = useMutation({
    mutationFn: updateUser,
    onError(err) {
      //console.log(err);
      toast.error(
        "We had an error saving your TON address, please try again later"
      );
    },
  });

  const handleChangeTonAddress = (event) => {
    const value = event.target.value;
    setTempTonAdress(value);
  };

  const handleSaveAddress = () => {
    //console.log("handleSaveAddress", tempTonAddress);
    setTonAdress(tempTonAddress);
    updateUserFn({
      address: account,
      network: selectedNetwork,
      data: {
        ton_address: tempTonAddress,
      },
    });
  };

  useEffect(() => {
    const { ton_address } = userData?.data || {};
    if (ton_address) setTonAdress(ton_address);
  }, [userData?.data, setTonAdress]);

  if (!isClientConnected) return <Connect />;

  return (
    <>
      {!selectedNetwork ||
        (selectedNetwork !== "TON" && (
          <div className="project_card_footer_2row">
            <label htmlFor="TonAddress">TON Wallet Address:</label>
            <input
              type="text"
              id="TonAddress"
              className="form-control"
              disabled={tonAddress}
              value={tonAddress || tempTonAddress}
              onChange={handleChangeTonAddress}
            />
            {tonAddress ? (
              <Button
                type="button"
                sm
                variant="dark"
                onClick={() => {
                  setTonAdress(null);
                }}
                className="iconButton"
              >
                <FaPencilAlt />
              </Button>
            ) : (
              <Button sm variant="dark" onClick={handleSaveAddress}>
                Save
              </Button>
            )}
          </div>
        ))}
      <Contribute
        max_allocation={max_allocation}
        id={id}
        pool_address_bsc={pool_address_bsc}
        pool_address_eth={pool_address_eth}
        pool_address_sol={pool_address_sol}
        pool_address_ton={pool_address_ton}
      />
    </>
  );
};

export default ProjectInfoActions;
