import { useState } from "react";
import { ModalContext } from "./ModalContext";


const ContextProvider = ({ children }) => {
  const [shareModalVisibility, setShareModalvisibility] = useState(false);
  const [account, setAccount] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [isClientConnected, setIsClientConnected] = useState(false);


  const shareModalHandle = (e) => {
    e.preventDefault();
    setShareModalvisibility(!shareModalVisibility);
  };

  const handleConnectedClient = (address) => {
    setIsClientConnected(true);
    setAccount(address);
    document.cookie = `isConnected=${selectedNetwork};;path=/`
  }

  const handleDisconnectedClient = () => {
    setIsClientConnected(false);
    document.cookie = `isConnected=false`
  }


  return (
    <ModalContext.Provider
      value={{
        shareModalVisibility,
        shareModalHandle,
        selectedNetwork,
        setSelectedNetwork,
        isClientConnected,
        handleConnectedClient,
        handleDisconnectedClient,
        account
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ContextProvider;
