import { useModal } from "utils/ModalContext";
//import { useCookies } from "react-cookie";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import {DropdownSelect} from "../Connect.style";


const NetworkSelect = () => {
  const { selectedNetwork, setSelectedNetwork, isClientConnected } = useModal();
  //const [setCookies] = useCookies();

  const handleSelectNetwork = (network) => {
    //setCookies('networkSelected', network)
    document.cookie = `networkSelected=${network};;path=/`
    setSelectedNetwork(network);
  };

  if (!isClientConnected) return (
      <DropdownSelect>
        {selectedNetwork ? selectedNetwork : <span>Select</span>}
        <MdOutlineKeyboardArrowDown />
        <div className="wallet_token_list">
          <a href="" onClick={() => handleSelectNetwork("TON")}>TON</a>
          <a href="" onClick={() => handleSelectNetwork("ETH")}>ETH</a>
          <a href="" onClick={() => handleSelectNetwork("BSC")}>BSC</a>
          <a href="" onClick={() => handleSelectNetwork("SOL")}>SOL</a>
        </div>
      </DropdownSelect>
  );
  return null;
};

export default NetworkSelect;
