import {
  useSendTransaction,
  useWaitForTransaction
} from "wagmi";
import {
  parseEther
} from "viem";
import {
  SendTransactionRequest,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import {
  Connection,
  PublicKey,
  Transaction,
  TransactionInstruction,
} from '@solana/web3.js';
import {
  useWallet
} from '@solana/wallet-adapter-react';

import {
  useModal
} from "utils/ModalContext";

import {
  useEffect,
  useState
} from "react";
import {
  toast
} from "react-toastify";


const useTransaction = (amountAllocation, pool_address_bsc, pool_address_eth, pool_address_sol, pool_address_ton) => {
  const {
    selectedNetwork,
    account
  } = useModal();
  const [tonConnectUI] = useTonConnectUI();


  const {
    data: hash,
    error: wagmiError,
    isPending,
    sendTransaction,
  } = useSendTransaction();

  /*  console.log('useSendTransaction 1', hash)
   console.log('useSendTransaction 2', wagmiError)
   console.log('useSendTransaction 3', isPending)
   console.log('useSendTransaction 4', sendTransaction)
  */
  const {
    isSuccess: isConfirmed
  } = useWaitForTransaction({
    hash,
  });
  const {
    publicKey
  } = useWallet();
  const solanaWallet = useWallet();

  //const [transactionStatus, setTransactionStatus] = useState('');

  const handleContributeTon = () => {
    const amount = parseInt((Number(amountAllocation) * 10 ** 9).toString());
    if (isNaN(amount) || amount <= 0) {
      //alert('Por favor, insira um valor válido para contribuição.');

      //NotificationManager.error('Por favor, insira um valor válido para contribuição.');
      return;
    }

    const defaultTx = {
      validUntil: Math.floor(Date.now() / 1000) + 600,
      messages: [
        {
          address: pool_address_ton,
          amount: amount.toString(),
          stateInit: 'te6cckEBBAEAOgACATQCAQAAART/APSkE/S88sgLAwBI0wHQ0wMBcbCRW+D6QDBwgBDIywVYzxYh+gLLagHPFsmAQPsAlxCarA==',
          payload: 'te6ccsEBAQEADAAMABQAAAAASGVsbG8hCaTc/g==',
        },
      ],
    };

    tonConnectUI.sendTransaction(defaultTx,{
      saveContribution
    });

    //NotificationManager.success('Contribuição realizada com sucesso!');
  };

  const saveContribution = (protocol) => {
    if (protocol) console.log(protocol);
  };

  const handleSolanaTransaction = async () => {
    try {
      // Verifica se o valor é válido
      if (!amountAllocation || isNaN(amountAllocation) || parseFloat(amountAllocation) <= 0) {
        throw new Error('Insira um valor válido para a transação.');
      }

      // Converte o valor para o formato de Solana (lamports)
      const lamports = Math.round(parseFloat(amountAllocation) * 1000000000); // 1 SOL = 1 bilhão de lamports

      // Verifica se a carteira está conectada
      if (!solanaWallet.connected) {
        throw new Error('Conecte sua carteira Solana para realizar a transação.');
      }

      console.log('solanaWallet', publicKey.toBase58())

      // Lógica para realizar a transação
      const connection = new Connection('https://wild-maximum-feather.solana-mainnet.quiknode.pro/bad28e86405bb95ac15dfb25ee3bec4ff4dc4595/');

      const fromAccountInfo = await connection.getAccountInfo(new PublicKey(solanaWallet));
      console.log('fromAccountInfo', fromAccountInfo, account)
      if (!fromAccountInfo) {
        throw new Error('Conta do remetente não encontrada.');
      }

      const transaction = new Transaction().add(
        TransactionInstruction.newTransfer({
          fromPubkey: new PublicKey(account),
          toPubkey: new PublicKey(pool_address_sol),
          lamports,
        })
      );

      console.log(transaction)

      transaction.recentBlockhash = (
        await connection.getRecentBlockhash('max')
      ).blockhash;

      solanaWallet.signTransaction(transaction, connection);

      const signature = await connection.sendRawTransaction(transaction.serialize());
      //setTransactionStatus(`Transação enviada: ${signature}`);
      console.log(`Transação enviada: ${signature}`)
    } catch (error) {
      console.error('Erro ao realizar transação:', error.message);
      //setTransactionStatus(`Erro ao realizar transação: ${error.message}`);
    }
  };

  const handleContribute = async () => {
    if (selectedNetwork === "ETH" || selectedNetwork === "BSC") {
      const to =
        selectedNetwork === "ETH" ? pool_address_eth : pool_address_bsc;
      sendTransaction({
        to,
        value: parseEther(amountAllocation)
      });
    } else if (selectedNetwork === "TON") {
      handleContributeTon()
    } else handleSolanaTransaction()
  };

  useEffect(() => {
    if (wagmiError) {
      toast.error(
        "There was a problem with the transaction, please try again later."
      );
    } else if (hash && !isPending) {
      //post para create contribution
    }
  }, [hash, isPending, wagmiError]);

  useEffect(() => {
    if (wagmiError) {
      toast.error(
        "There was a problem with the transaction, please try again later."
      );
    } else if (isConfirmed) {
      //post para create contribution
    }
  }, [hash, isConfirmed, wagmiError]);

  return {
    loading: isPending,
    error: wagmiError,
    hash: hash,
    handleContribute
  };
};

export default useTransaction;