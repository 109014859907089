import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { getProjects } from "api/getProjects";
import { useQuery } from "@tanstack/react-query";
import { SectionTitle } from "components/sectionTitle";
import Button from "components/button";
import ProjectCard from "./ProjectCard/ProjectCard";
import Loading from "components/loading/Loading";
import {ExploreProjectsStyleWrapper, ExploreProjectsEmptyMsg} from "./ExploreProjects.style";

const ExploreProjects = () => {
  const {
    data: projectsResult,
    isLoading: isLoadingProjects,
    error,
  } = useQuery({
    queryKey: ["getAllProjects"],
    queryFn: () => getProjects(),
    retry: 2,
  });

  if (error) console.log(error);

  const splitProjects = () => {
    const projectList = projectsResult?.data;

    if(!projectList) return {upcoming: [], ongoing: []};

    const upcoming = projectList.filter(project => project.status === 'upcoming');
    const ongoing = projectList.filter(project => project.status === 'active');
    return { upcoming, ongoing };
  }
  const splittedProjects = splitProjects();

  return (
    <ExploreProjectsStyleWrapper>
      <div className="container">
        <SectionTitle title="ONGOING IDO" subtitle="PROJECTS" />
        <div className="row">
          <ul className="menu-list">
            <li>Project name</li>
            <li>Chain</li>
            <li>Launched</li>
            <li>Total Raise</li>
            <li>Progress</li>
          </ul>
        </div>
        <div className="projects-row">
          <Tabs>
            <TabList>
                <Tab key="ongoing">
                  <Button
                    variant="outline"
                    sm
                    onClick={(e) => e.preventDefault()}
                  >
                    Ongoing
                  </Button>
                </Tab>
                <Tab key="upcoming">
                  <Button
                    variant="outline"
                    sm
                    onClick={(e) => e.preventDefault()}
                  >
                    Upcoming
                  </Button>
                </Tab>
            </TabList>

            <TabPanel className="row tabs-row">
              {isLoadingProjects && (
                <Loading rowAmount={2} colAmount={1} Itemheight="130px" />
              )}
              {splittedProjects?.ongoing.length  ?
                splittedProjects.ongoing.map((project) => (
                  <div key={project.id + '-' + project.title} className="col-md-12">
                    <ProjectCard {...project} />
                  </div>
                )) : <ExploreProjectsEmptyMsg>No ongoing projects</ExploreProjectsEmptyMsg>
              }
            </TabPanel>
            <TabPanel className="row tabs-row">
              {isLoadingProjects && (
                <Loading rowAmount={2} colAmount={1} Itemheight="130px" />
              )}
              {splittedProjects?.upcoming.length  ?
                splittedProjects.upcoming.map((project) => (
                  <div key={project.id + '-' + project.title} className="col-md-12">
                    <ProjectCard {...project} />
                  </div>
                )) : <ExploreProjectsEmptyMsg>No upcoming projects</ExploreProjectsEmptyMsg>
              }
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </ExploreProjectsStyleWrapper>
  );
};

export default ExploreProjects;
