import { api } from "utils/services";

export async function getPoolBalance({ pool_address, network }) {

    //console.log('gpb params', pool_address, network)

  const response = await api.get("/getPoolBalance", {
    params: {
        pool_address,
        network
    },
  })

  //console.log('gpb response', response)
  return response.data;
}