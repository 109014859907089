import CardHover from "components/cardHover";
import ProjectCardStyleWrapper from "./ProjectCard.style";

const ProjectCard = ({
  thumb,
  title,
  price,
  coinIcon,
  socialLinks,
  network,
  pool_address_ton,
  pool_address_eth,
  pool_address_bsc,
  pool_address_sol,
  tgeTime,
  access,
  max_allocation,
  totalValue
}) => {
  const params = () => {
    const networkToPool = {
      TON: pool_address_ton,
      ETH: pool_address_eth,
      BSC: pool_address_bsc,
      SOL: pool_address_sol,
    };
    return `/${networkToPool[network]}/${network}`;
  };

  const calculateEndDate = () => {
    const launchDate = new Date(tgeTime);
    const currentDate = new Date();

    const dayInMillisseconds = 1000 * 60 * 60 * 24;

    const launchDateFromNow = launchDate - currentDate;
    const launchDateFromNowInDays = Math.ceil(
      launchDateFromNow / dayInMillisseconds
    );

    if (launchDateFromNowInDays < 0) return null;
    return Math.abs(launchDateFromNowInDays);
  };

  return (
    <ProjectCardStyleWrapper className="project_item_wrapper">
      <div className="project-info d-flex">
        <a href={`/projects-details${params()}`}>
          <img src={thumb} alt="project thumb" height="300" />
        </a>
        <div className="project-auother">
          <h4 className="mb-10">
            <a href={`/projects-details${params()}`}>{title}</a>
          </h4>
          <div className="dsc">PRICE (GAC) = {price}</div>
        </div>
      </div>
      <div className="project-content">
        <div className="project-header d-flex justify-content-between align-items-center">
          <div className="heading-title">
            {calculateEndDate() ? (
              <h4>{calculateEndDate()} Days Left</h4>
            ) : (
              <h4>Ended</h4>
            )}
          </div>
          <div className="project-icon">
            <img src={coinIcon} alt="coin icon" width="35" height="35" />
          </div>
        </div>
        <ul className="project-listing">
            <li>
              Max allocation <span>{max_allocation} USDT</span>
            </li>
            <li>
              Targeted raise <span>{totalValue} USDT</span>
            </li>
            <li>
              Access type <span>{access}</span>
            </li>
        </ul>
        <div className="social-links">
          {socialLinks?.map((profile, i) => (
            <a key={i} href={profile.url}>
              <img src={profile.icon} alt="social icon" />
            </a>
          ))}
        </div>
      </div>

      <CardHover />
    </ProjectCardStyleWrapper>
  );
};

export default ProjectCard;
