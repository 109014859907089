import StatisticsStyleWrapper from "./Statistics.style";
import { PieChart, Pie, Cell } from "recharts";

const Statistics = ({ content }) => {
  const colors = [
    "#3db439",
    "#9f27aa",
    "#2e35a8",
    "#3a88ab",
    "#8032b2",
    "#1d2374",
    "#8a3e3e",
  ];

  return (
    <StatisticsStyleWrapper id="tokenomics">
      <div className="tokenomics_content">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="tokenomics_content_right">
              <PieChart width={326} height={400}>
                <Pie
                  data={content}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  fill="#1e1f35"
                  dataKey="value"
                  innerRadius={70}
                >
                  {content.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="tokenomics_content_left">
              <div className="tokenomics_list">
                {content?.map((item, i) => (
                  <div key={i} className="tokenomics_item">
                    <h5 className="tokenomics_item_title">
                      {item?.name}
                      <span>{item?.value?.toFixed(2)}%</span>
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StatisticsStyleWrapper>
  );
};

export default Statistics;
