import styled from "styled-components";
import footerBg from "assets/images/bg/background-4.jpg";

const FooterStyleWrapper = styled.footer`
  position: relative;
  background:linear-gradient(900deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.9)), url(${footerBg});
  background-repeat: no-repeat; 
  background-size: cover;
`;

export default FooterStyleWrapper;
