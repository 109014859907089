import { api } from "utils/services";

export async function searchUser({ address, network }) {
  const response = await api.get("/searchUser", {
    params: {
        address,
        network
    },
  })
  return response.data;
}